@import "~@coreui/coreui/scss/coreui";
@import "~@coreui/icons/css/free.css";
@import "~@coreui/icons/css/free.min.css";
@import "~font-awesome/scss/font-awesome";

main > .container {
    padding: 80px 15px 0;
}

.footer {
    background-color: #f5f5f5;
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}

.search-list {
    background-color: #000;
    li {
        list-style: none;
    }
}

